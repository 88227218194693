import React from "react";
import { Image } from "react-bootstrap";
import NoDataImg from "../../images/no-data-found.svg";
const NoData = ({data}) => {
  return (
     <div className="nodatafound my-4 d-flex align-items-center justify-content-center">
       <div className="nodatafoundinner">
        <Image src={NoDataImg} className="img-fluid" alt=""/>
        <h3 style={{fontSize:"1.25rem"}} className="text-center mt-3">No {data} Found</h3>
      </div>
     </div>
  );
};
export default NoData;
