import React, { createContext, useContext, useEffect, useState } from "react";
import { LocalStorage } from "../utils/HelperFunction";
import { useSelector } from "react-redux";
import socketio from "socket.io-client";
const getSocket = () => {
  const token = LocalStorage.get("token");
  if (!token) {
    return;
  }
  const socketUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SOCKET_SERVER
      : process.env.REACT_APP_SOCKET_LOCAL;
  const socketPath =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SOCKET_PATH_SERVER
      : process.env.REACT_APP_SOCKET_PATH_LOCAL;
  const socket = socketio(socketUrl, {
    path: socketPath,
    withCredentials: true,
    auth: { token },
  });
  socket.on("connect_error", (error) => {
    console.error("WebSocket connection error:", error);
  });

  // Event listener for generic error
  socket.on("error", (error) => {
    console.error("WebSocket error:", error);
  });

  // Event listener for disconnect
  socket.on("disconnect", (reason) => {
    console.log("Disconnected:", reason);
  });
  return socket;
};
const SocketContext = createContext({
  socket: null,
});

const useSocket = () => {
  return useContext(SocketContext);
};

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const userdetails = useSelector((state) => state.User);
  useEffect(() => {
    setSocket(getSocket());
  }, [userdetails._id]);
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
export { SocketProvider, useSocket };
