import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Image,
  Modal,
  Button,
  ProgressBar,
  Dropdown,
} from "react-bootstrap";
import Col1Icon from "../../images/college.png";
import SearchIcon from "../../images/searchicon.svg";
import Col2Icon from "../../images/colimg2.png";
import Col3Icon from "../../images/colimg3.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Helper } from "../../../utils/HelperFunction";
import { API_URL } from "../../../utils/API_URL";
import PageLoader from "../customcomonents/PageLoader";

const CompareCollege = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const college_id = searchParams.get("college_id");
  const [collegeSearchList, setCollegeSearchList] = useState(null);
  const [modalShow1, setModalShow1] = useState(false);
  const [collegeIds, setCollegeIds] = useState([+college_id]);

  useEffect(() => {
    let url = `${API_URL.COLLEGE_LIST}?college_id=${collegeIds?.map(
      (id) => id
    )}&details=true`;
    fetchCollegeList(url);
  }, [collegeIds]);
  // console.log(55, collegeIds);
  const fetchCollegeList = async (url) => {
    try {
      const result = await Helper.GetData(url);
      const data = result.data?.data;
      let obj;
      const list = data.map((clg) => {
        obj = {
          id: clg["collegeUnitId"],
          name: clg["name"],
          address: clg["addr"],
          logo: clg["logoImage"],
          admission_rate: clg["acceptanceRate"] || "N/A",
          under_graduate_students: clg["undergraduateSize"] || 0,
          your_price: clg["avgNetPrice"],
          ownership: clg["latest.school.peps_ownership"],
          avg_sat: clg?.satAverage || "N/A",
          avg_act: clg?.actCumulativeMidpoint || "N/A",
          total_students: clg["latest.student.size"] || 0,
          in_state_price: clg["inStateTuition"] || 0,
          out_state_price: clg["outOfStateTuition"],
          roomboard: clg["typicalRoomAndBoard"],
          avg_net_price: clg["avgNetPrice"],
          booksupply: clg["acceptanceRate"],
          aid: clg["latest.aid.number.overall"],
          retention_rate:
            clg["latest.student.retention_rate.overall.full_time"],
          avg_stu_debt_upon_grad: "16,250",
          stu_rec_fin_aid: clg.percentOfStudentsWhoReceiveFinancialAid,
          application_fee: "41",
          full_time_ungrad: "80%",
        };
        // obj = {
        //   id: clg["id"],
        //   name: clg["latest.school.name"],
        //   address: clg["latest.school.address"],
        //   admission_rate:
        //     clg["latest.admissions.admission_rate.overall"] || "N/A",
        //   under_graduate_students:
        //     clg["latest.student.enrollment.undergrad_12_month"] || 0,
        //   your_price: clg["latest.cost.tuition.in_state"],
        //   logo: Col1Icon,
        //   ownership: clg["latest.school.peps_ownership"],
        //   avg_sat: clg["latest.admissions.sat_scores.average.overall"] || "N/A",
        //   avg_act: clg["latest.admissions.act_scores.midpoint.cumulative"] || "N/A",
        //   total_students: clg["latest.student.size"] || 0,
        //   out_state_price: clg["latest.cost.tuition.out_of_state"],
        //   booksupply:clg["latest.cost.booksupply"],
        //   roomboard: clg["latest.cost.roomboard.offcampus"],
        //   avg_net_price: clg["latest.cost.avg_net_price.overall"],
        //   aid: clg["latest.aid.number.overall"],
        //   retention_rate: clg["latest.student.retention_rate.overall.full_time"],
        //   avg_stu_debt_upon_grad: "16,250",
        //   stu_rec_fin_aid: "47%",
        //   application_fee: "41",
        //   full_time_ungrad: "80%",
        // };
        return obj || [];
      });
      setCollegeSearchList(list);
    } catch (err) {
      console.error(err);
      setCollegeSearchList([]);
    }
  };
  // console.log(collegeSearchList);
  return (
    <React.Fragment>
      <section className="homePad bgColor">
        <div className="centerSearch">
          <h1>Add a college to compare</h1>
          {/* <div className="serchBox">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                fill="#8E8EA1"
              />
            </svg>

            <Form.Control type="email" placeholder="Enter email" />
          </div> */}
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={11}>
              {collegeSearchList ? (
                <Row>
                  {collegeSearchList &&
                    collegeSearchList?.map((clg) => (
                      <Col lg={4} key={clg.id}>
                        <div className="campus_box cmb15">
                          <div className="campus_body campus_body1 campus_body2">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant={null}
                                id="dropdown-basic"
                                className="menuDots menuDots1"
                              >
                                <Icon icon="charm:menu-kebab" fontSize={24} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {/* <Dropdown.Item>
                                  Edit
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  onClick={() => {
                                    const data = collegeIds.filter(
                                      (id) => id !== clg.id
                                    );
                                    setCollegeIds(data);
                                    console.log(9999, data);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Image src={clg.logo} alt="" />
                            <h2>{clg.name}</h2>
                            <div className="para">
                              <p>
                                {clg.address} <Icon icon="mdi:dot" />{" "}
                                {clg.ownership}
                                {/* <Icon icon="mdi:dot" /> For-profit */}
                              </p>
                            </div>
                            <hr />
                            <h6>Admissions</h6>
                            <div className="avgDetails">
                              <div>
                                <h5>Average SAT</h5>
                                <h4>{clg.avg_sat}</h4>
                              </div>
                              <div>
                                <h5>Average ACT</h5>
                                <h4>{clg.avg_act}</h4>
                              </div>
                              <div>
                                <h5>Booksupply fee</h5>
                                <h4>$ {clg.booksupply}</h4>
                              </div>
                            </div>
                            <h6>Student Life</h6>
                            <div className="avgDetails">
                              <div>
                                <h5>Undergrads</h5>
                                <h4>{clg.under_graduate_students}</h4>
                              </div>
                              <div>
                                <h5>No. of Students</h5>
                                <h4>{clg.total_students}</h4>
                              </div>
                            </div>
                            <hr />

                            <div className="proBox">
                              <div className="proDat">
                                <span>Full-Time Undergrades</span>
                                <strong>{clg.full_time_ungrad}</strong>
                              </div>
                              <div>
                                <ProgressBar variant="primary" now={80} />
                              </div>
                            </div>

                            <div className="proBox">
                              <div className="proDat">
                                <span>Retention</span>
                                <strong>{clg.retention_rate}%</strong>
                              </div>
                              <div>
                                <ProgressBar
                                  variant="warning"
                                  now={clg.retention_rate}
                                />
                              </div>
                            </div>

                            <h6>Cost & Financial Aid</h6>
                            <h5 className="tuLabel">Tution</h5>
                            <div className="avgDetails">
                              <div>
                                <h5>In-State</h5>
                                <h4>$ {clg.your_price}</h4>
                              </div>
                              <div>
                                <h5>Out-of-State</h5>
                                <h4>$ {clg.out_state_price}</h4>
                              </div>
                            </div>
                            <div className="avgDetails">
                              <div>
                                <h5>Room & Board</h5>
                                <h4>$ {clg.roomboard}</h4>
                              </div>
                              <div>
                                <h5>Average Net Price</h5>
                                <h4>$ {clg.avg_net_price}</h4>
                              </div>
                            </div>
                            <div className="proBox">
                              <div className="proDat">
                                <span>Students Receiving Financial Aid</span>
                                <strong>{clg.stu_rec_fin_aid}</strong>
                              </div>
                              <div>
                                <ProgressBar variant="primary" now={47} />
                              </div>
                            </div>
                            <h5 className="tuLabel tuLabel1">
                              Average Student Debt Upon Graduation
                            </h5>
                            <h6 className="totalLable">$ {clg.avg_stu_debt_upon_grad}</h6>
                          </div>
                        </div>
                      </Col>
                    ))}
                  {Array.from(
                    { length: 3 - collegeSearchList?.length },
                    (_, index) => (
                      <Col
                        lg={4}
                        key={index}
                        className="mb-3 pointer"
                        onClick={() => setModalShow1(true)}
                      >
                        <div className="addmorecompare_box">
                          <Icon icon="gala:add" />
                          <h4>Add College</h4>
                        </div>
                      </Col>
                    )
                  )}
                  {/* <Col lg={4}>
                  <div className="campus_box cmb15">
                    <div className="campus_body campus_body1 campus_body2">
                      <Button variant="primary" className="menuDots menuDots1">
                        <Icon icon="charm:menu-kebab" fontSize={24} />
                      </Button>
                      <Image src={Col3Icon} alt="" />
                      <h2>Chapman University</h2>
                      <div className="para">
                        <p>
                          Paler, AK <Icon icon="mdi:dot" /> Private Not{" "}
                          <Icon icon="mdi:dot" /> For-profit
                        </p>
                      </div>
                      <hr />
                      <h6>Admissions</h6>
                      <div className="avgDetails">
                        <div>
                          <h5>Average SAT</h5>
                          <h4>880</h4>
                        </div>
                        <div>
                          <h5>Average ACT</h5>
                          <h4>22</h4>
                        </div>
                        <div>
                          <h5>Application fee</h5>
                          <h4>$ 28</h4>
                        </div>
                      </div>
                      <h6>Student Life</h6>
                      <div className="avgDetails">
                        <div>
                          <h5>Undergrads</h5>
                          <h4>1,625</h4>
                        </div>
                        <div>
                          <h5>No. of Students</h5>
                          <h4>3,090</h4>
                        </div>
                      </div>
                      <hr />

                      <div className="proBox">
                        <div className="proDat">
                          <span>Full-Time Undergrades</span>
                          <strong>64%</strong>
                        </div>
                        <div>
                          <ProgressBar variant="primary" now={64} />
                        </div>
                      </div>

                      <div className="proBox">
                        <div className="proDat">
                          <span>Retention</span>
                          <strong>40%</strong>
                        </div>
                        <div>
                          <ProgressBar variant="warning" now={40} />
                        </div>
                      </div>

                      <h6>Cost & Financial Aid</h6>
                      <h5 className="tuLabel">Tution</h5>
                      <div className="avgDetails">
                        <div>
                          <h5>In-State</h5>
                          <h4>$ 6,072</h4>
                        </div>
                        <div>
                          <h5>Out-of-State</h5>
                          <h4>$ 15,144</h4>
                        </div>
                      </div>
                      <div className="avgDetails">
                        <div>
                          <h5>Room & Board</h5>
                          <h4>$ 8,916</h4>
                        </div>
                        <div>
                          <h5>Average Net Price</h5>
                          <h4>$ 12,252</h4>
                        </div>
                      </div>
                      <div className="proBox">
                        <div className="proDat">
                          <span>Students Receiving Financial Aid</span>
                          <strong>67%</strong>
                        </div>
                        <div>
                          <ProgressBar variant="primary" now={67} />
                        </div>
                      </div>
                      <h5 className="tuLabel tuLabel1">
                        Average Student Debt Upon Graduation
                      </h5>
                      <h6 className="totalLable">$ 16,265</h6>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="campus_box cmb15">
                    <div className="campus_body campus_body1 campus_body2">
                      <Button variant="primary" className="menuDots menuDots1">
                        <Icon icon="charm:menu-kebab" fontSize={24} />
                      </Button>
                      <Image src={Col2Icon} alt="" />
                      <h2>University of San Diego</h2>
                      <div className="para">
                        <p>
                          Paler, AK <Icon icon="mdi:dot" /> Private Not{" "}
                          <Icon icon="mdi:dot" /> For-profit
                        </p>
                      </div>
                      <hr />
                      <h6>Admissions</h6>
                      <div className="avgDetails">
                        <div>
                          <h5>Average SAT</h5>
                          <h4>990</h4>
                        </div>
                        <div>
                          <h5>Average ACT</h5>
                          <h4>20</h4>
                        </div>
                        <div>
                          <h5>Application fee</h5>
                          <h4>$ 30</h4>
                        </div>
                      </div>
                      <h6>Student Life</h6>
                      <div className="avgDetails">
                        <div>
                          <h5>Undergrads</h5>
                          <h4>1,834</h4>
                        </div>
                        <div>
                          <h5>No. of Students</h5>
                          <h4>2,132</h4>
                        </div>
                      </div>
                      <hr />

                      <div className="proBox">
                        <div className="proDat">
                          <span>Full-Time Undergrades</span>
                          <strong>50%</strong>
                        </div>
                        <div>
                          <ProgressBar variant="primary" now={50} />
                        </div>
                      </div>

                      <div className="proBox">
                        <div className="proDat">
                          <span>Retention</span>
                          <strong>59%</strong>
                        </div>
                        <div>
                          <ProgressBar variant="warning" now={59} />
                        </div>
                      </div>

                      <h6>Cost & Financial Aid</h6>
                      <h5 className="tuLabel">Tution</h5>
                      <div className="avgDetails">
                        <div>
                          <h5>In-State</h5>
                          <h4>$ 5,021</h4>
                        </div>
                        <div>
                          <h5>Out-of-State</h5>
                          <h4>$ 18,255</h4>
                        </div>
                      </div>
                      <div className="avgDetails">
                        <div>
                          <h5>Room & Board</h5>
                          <h4>$ 7,002</h4>
                        </div>
                        <div>
                          <h5>Average Net Price</h5>
                          <h4>$ 11,120</h4>
                        </div>
                      </div>
                      <div className="proBox">
                        <div className="proDat">
                          <span>Students Receiving Financial Aid</span>
                          <strong>67%</strong>
                        </div>
                        <div>
                          <ProgressBar variant="primary" now={67} />
                        </div>
                      </div>
                      <h5 className="tuLabel tuLabel1">
                        Average Student Debt Upon Graduation
                      </h5>
                      <h6 className="totalLable">$ 16,265</h6>
                    </div>
                  </div>
                </Col> */}
                </Row>
              ) : (
                <PageLoader />
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <EditPrefrenceModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        collegeIds={collegeIds}
        setCollegeIds={setCollegeIds}
      />
    </React.Fragment>
  );
};

function EditPrefrenceModal(props) {
  const [collegeSearchList, setCollegeSearchList] = useState([]);
  const [searchCollege, setSearchCollege] = useState("");
  useEffect(() => {
    let url = `${API_URL.COLLEGE_LIST}?name=${searchCollege}&dropdown=yes`;
    searchCollege && fetchCollegeList(url);
  }, [searchCollege]);
  const fetchCollegeList = async (url) => {
    try {
      const result = await Helper.GetData(url);
      const data = result.data?.data;
      let obj;
      const list = data.map((clg) => {
        obj = {
          id: clg.unitId,
          name: clg["name"],
        };
        return obj || [];
      });
      setCollegeSearchList(list);
    } catch (err) {
      console.error(err);
      setCollegeSearchList([]);
    }
  };
  const handleClickUser = (id) => {
    console.log(8888,id)
    setSearchCollege("");
    setCollegeSearchList([]);
    props.setCollegeIds((prev) => {
      if (!prev.some((item) => item === id)) {
        if (prev.length < 3) {
          return [...prev, id];
        }
      }
      return prev;
    });
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal_css"
    >
      <Modal.Body>
        <div className="modalheading_box">
          <h1 className="uFlex justify-content-between  pb-0 mt-0 border-0  w-100 mb-0">
            {/* Edit Preferences
            <Icon
              className="cursor-pointer"
              icon="prime:times-circle"
              onClick={props.onHide}
              fontSize={35}
            /> */}
            <div className="compare-search">
              <Form.Control
                placeholder="  Search to add more colleges"
                name="searchTerm"
                value={searchCollege}
                onChange={(e) => {
                  if (props.collegeIds.length < 3) {
                    return setSearchCollege(e.target.value);
                  } else {
                    return setSearchCollege("");
                  }
                }}
              />
              <Image className="search-image" src={SearchIcon} alt="" />

              {collegeSearchList && collegeSearchList.length > 0 && (
                <div className="search-college-dropdown">
                  {collegeSearchList?.map((clg) => (
                    <span
                      key={clg.id}
                      className="drop_down_list"
                      onClick={() => handleClickUser(clg.id)}
                    >
                      <strong>{clg.name}</strong>
                    </span>
                  ))}
                </div>
              )}
            </div>
          </h1>
          {/* <Link onClick={props.onHide}>
            <Icon icon="iwwa:delete" fontSize={14} />
          </Link> */}
        </div>

        {/* <ul className="edit_listpref">
          <li>
            <span>1.</span> <Image src={Col1Icon} alt="" /> Gonzaga University
            <Link>Remove</Link>
          </li>
          <li>
            <span>2.</span> <Image src={Col2Icon} alt="" /> Chapman University
            <Link>Remove</Link>
          </li>
          <li>
            <span>3.</span> <Image src={Col3Icon} alt="" /> University of San
            Diego
            <Link>Remove</Link>
          </li>
        </ul> */}

        {/* <p className="coll_edit">You can add one more college</p> */}

        {/* <div className="modal_footer">
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={props.onHide}
          >
            Close
          </Button>

          <Button variant="primary" as={Link} to="/addacollege">
            Compare
          </Button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

export default CompareCollege;
