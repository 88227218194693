import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  Major:[]
}
  

const fetchMajor = createAsyncThunk(
  "major/fetchMajor",
  async () => {
    try {
      const url = API_URL.MAJOR_LIST;
      const result = await Helper.GetData(url);
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);
export const majorSlice = createSlice({
  name: "major",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMajor.fulfilled, (state, action) => {
      const { status, data } = action.payload;
      if (status && data?.length > 0) {
        return {
          ...state,
          Major:[...data],
        };
      }
    });
  },
});
export { fetchMajor };
export default majorSlice.reducer;
