import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  notificationList:undefined,
  unreadNotification:0
}
  

const fetchNotifications = createAsyncThunk(
  "notification/fetchNotifications",
  async () => {
    try {
      const url = API_URL.GET_NOTIFICATIONS;
      const result = await Helper.GetData(url);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
);
const fetchUnreadNotification = createAsyncThunk("notification/fetchUnreadNotification", async () => {
  try {
    const url = API_URL.GET_NOTIFICATION_COUNT;
    const result = await Helper.GetData(url);
    return result.data;
  } catch (error) {
    throw error;
  }
});
const updateUnreadNotification = createAsyncThunk("notification/updateUnreadNotification", async (obj,{dispatch}) => {
  try {
    const url = API_URL.UPDATE_NOTIFICATION_COUNT;
    console.log("unread",obj)
    const result = await Helper.PatchData(url,obj);
    dispatch(fetchUnreadNotification())
    return result.data;
  } catch (error) {
    throw error;
  }
});
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      const { status, data } = action.payload;
      if (status && data?.length > 0) {
        return {
          ...state,
          notificationList:[...data],
        };
      }else{
        return {
          ...state,
          notificationList:[],
        };
      }
    });
    builder.addCase(fetchUnreadNotification.fulfilled, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        unreadNotification:data,
      };
    });
    builder.addCase(updateUnreadNotification.fulfilled, (state, action) => {});
  },
});
export { fetchNotifications,fetchUnreadNotification,updateUnreadNotification };
export default notificationSlice.reducer;
