import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  followings: [],
  followers: [],
  friends: [],
  invitation: [],
  sent_friends: [],
};
const fetchMyNetwork = createAsyncThunk("network/fetchMyNetwork", async () => {
  try {
    const url = API_URL.FETCH_CONNECTIONS;
    const result = await Helper.GetData(url);
    return result.data.data;
  } catch (error) {
    throw error;
  }
});
const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMyNetwork.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
  },
});
export { fetchMyNetwork };
export default networkSlice.reducer;
