import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

const MayRenderNav = ({ children }) => {
  const location = useLocation();
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setShowNavbar(true);
    } else {
      setShowNavbar(true);
    }
  }, [location]);

  return <div>{showNavbar && children}</div>;
};

export default MayRenderNav;
