import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "react";
import LandingHome from "../component/pages/auth/Home";
import SignUp from "../component/pages/auth/SignUp";

const ForgotPassword = lazy(() =>
  import("../component/pages/auth/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../component/pages/auth/ResetPassword")
);
const Otp = lazy(() => import("../component/pages/auth/Otp"));
const Blocked = lazy(() => import("../component/pages/auth/Blocked"));
const FAQS = lazy(() => import("../component/pages/faqs"));
const TermsAndConditions = lazy(() =>
  import("../component/pages/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("../component/pages/PrivacyPolicy"));
const SignIn = lazy(() => import("../component/pages/auth/SignIn"));
const PublicRoute = ({ setPathname }) => {
  return (
    // <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<LandingHome setPathname={setPathname} />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/no-auth" element={<Blocked />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/faqs" element={<FAQS />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsconditions" element={<TermsAndConditions />} />
      </Routes>
    // </Suspense>
  );
};

export default PublicRoute;
