import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FooterLogo from "../images/footer-logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Footer = () => {
  const userDetails = useSelector((state) => state.User);
  // const [showButton, setShowButton] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setShowButton(window.pageYOffset > 100);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth"
  //   });
  // };

  return (
    <>
      {/* <button
        className="scroll-to-top-button"
        onClick={scrollToTop}
        style={{ display: showButton ? "block" : "none" }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M13 20h-2V8l-5.5 5.5l-1.42-1.42L12 4.16l7.92 7.92l-1.42 1.42L13 8z"/></svg>
      </button> */}
      <footer className="footer">
        <Container>
          <Row>
            <Col
              xl={3}
              lg={3}
              className="d-flex justify-content-start align-items-center"
            >
              <div className="footer-info mb-2">
                <img
                  className="footer-logo"
                  src={FooterLogo}
                  alt="footer-logo"
                />
              </div>
            </Col>

            <Col sm={6} xs={6} lg={3} xl={3}>
              <div className="footer-links fl-1">
                <h6 className="s-17 w-700">Quick Links</h6>

                <ul className="foo-links clearfix">
                  {/* <li>
                  <p>
                    <Link to="/">Home</Link>
                  </p>
                </li>

                <li>
                  <p>
                    <Link to="/my-network">My Network</Link>
                  </p>
                </li>

                <li>
                  <p>
                    <Link to="/groups">Groups</Link>
                  </p>
                </li>

                <li>
                  <p>
                    <Link to="/profile">Profile</Link>
                  </p>
                </li> */}

                  {userDetails?._id && (
                    <li>
                      <p>
                        <Link to="/resources">Resources</Link>
                      </p>
                    </li>
                  )}

                  <li>
                    <p>
                      <Link to="/faqs">FAQs</Link>
                    </p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col sm={6} xs={6} lg={3} xl={3}>
              <div className="footer-links fl-2">
                <h6 className="s-17 w-700">Useful Info</h6>

                <ul className="foo-links clearfix">
                  <li>
                    <p>
                      <Link to="/termsconditions">Terms & Conditions</Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to="/privacypolicy">Privacy Policy</Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to="/my-passion-project">Extracurriculars</Link>
                    </p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col sm={12} lg={3} xl={3}>
              <div className="footer-links">
                <h6 className="s-17 w-700">Contact Info</h6>

                <ul className="foo-address clearfix">
                  <li>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                      <g clipPath="url(#clip0_603_12147)">
                        <path
                          d="M9.50017 10.2063C10.7925 10.2063 11.8402 9.15874 11.8402 7.86634C11.8402 6.57402 10.7925 5.52637 9.50017 5.52637C8.20785 5.52637 7.16016 6.57402 7.16016 7.86634C7.16016 9.15874 8.20785 10.2063 9.50017 10.2063Z"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M3.21527 6.50129C4.69277 0.00629079 14.3153 0.0137917 15.7853 6.50879C16.6478 10.3188 14.2778 13.5438 12.2003 15.5388C10.6928 16.9938 8.30776 16.9938 6.79277 15.5388C4.72277 13.5438 2.35277 10.3113 3.21527 6.50129Z"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_603_12147">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(0.5 0.133789)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    1534 N. Moorpark Rd., #375 <br />
                    Thousand Oaks, CA 91360 USA
                  </li>
                  <li>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                      <path
                        d="M13.25 15.875H5.75C3.5 15.875 2 14.75 2 12.125V6.875C2 4.25 3.5 3.125 5.75 3.125H13.25C15.5 3.125 17 4.25 17 6.875V12.125C17 14.75 15.5 15.875 13.25 15.875Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.25 7.25L10.9025 9.125C10.13 9.74 8.8625 9.74 8.09 9.125L5.75 7.25"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <Link to="mailto:demouser@gmail.com">
                      support@briddg.com
                    </Link>
                  </li>
                  {/* <li>
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path
                      d="M17.0001 13.1901V15.4401C17.0009 15.6489 16.9581 15.8557 16.8745 16.0471C16.7908 16.2385 16.668 16.4103 16.5141 16.5515C16.3602 16.6927 16.1785 16.8002 15.9806 16.8671C15.7828 16.934 15.5731 16.9589 15.3651 16.9401C13.0572 16.6893 10.8403 15.9007 8.89257 14.6376C7.08044 13.4861 5.54407 11.9497 4.39257 10.1376C3.12506 8.18098 2.33625 5.95332 2.09007 3.63507C2.07133 3.42767 2.09598 3.21864 2.16245 3.02129C2.22892 2.82394 2.33575 2.64259 2.47615 2.48879C2.61654 2.33499 2.78743 2.2121 2.97792 2.12796C3.1684 2.04382 3.37433 2.00027 3.58257 2.00007H5.83257C6.19655 1.99649 6.54942 2.12538 6.82539 2.36272C7.10137 2.60006 7.28163 2.92966 7.33257 3.29007C7.42754 4.01012 7.60366 4.71712 7.85757 5.39757C7.95848 5.66602 7.98032 5.95776 7.9205 6.23823C7.86069 6.51871 7.72172 6.77616 7.52007 6.98007L6.56757 7.93257C7.63524 9.81023 9.18991 11.3649 11.0676 12.4326L12.0201 11.4801C12.224 11.2784 12.4814 11.1395 12.7619 11.0796C13.0424 11.0198 13.3341 11.0417 13.6026 11.1426C14.283 11.3965 14.99 11.5726 15.7101 11.6676C16.0744 11.719 16.4071 11.9025 16.645 12.1832C16.8828 12.4639 17.0092 12.8223 17.0001 13.1901Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Link to="tel:(+01) 123-345-4567">(+01) 123-345-4567</Link>
                </li> */}
                </ul>
              </div>
            </Col>
          </Row>

          <hr />

          <div className="bottom-footer">
            <div className="footer-copyright">
              <p className="p-sm">
                © Copyright 2023 <strong>BRiDDG</strong>.{" "}
                <span>All Rights Reserved</span>
              </p>
            </div>

            <ul className="bottom-footer-socialstext-end">
              {/* <li>
              <Link to="https://threads.com/briddgapp" target="_blank">
                <svg height="16" width="16" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"
                    fill="white"
                  />
                </svg>
              </Link>
            </li> */}

              <li>
                <Link to="https://twitter.com/BRiDDGApp/" target="_blank">
                  <svg width="17" height="13" viewBox="0 0 17 13" fill="none">
                    <path
                      d="M14.6893 3.23856C14.6995 3.38069 14.6995 3.52284 14.6995 3.66497C14.6995 7.99997 11.4 12.9949 5.36952 12.9949C3.51164 12.9949 1.78577 12.4568 0.333984 11.5228C0.597953 11.5533 0.851734 11.5634 1.12586 11.5634C2.65883 11.5634 4.07002 11.0457 5.19692 10.1624C3.7553 10.132 2.54717 9.18781 2.13092 7.88831C2.33398 7.91875 2.53702 7.93906 2.75023 7.93906C3.04464 7.93906 3.33908 7.89844 3.61317 7.82741C2.11064 7.52281 0.983703 6.20303 0.983703 4.60913V4.56853C1.42023 4.81219 1.92789 4.96447 2.46592 4.98475C1.58267 4.39591 1.00402 3.39084 1.00402 2.25378C1.00402 1.64466 1.16642 1.08628 1.4507 0.598969C3.06492 2.58881 5.49133 3.88828 8.21211 4.03044C8.16136 3.78678 8.13089 3.533 8.13089 3.27919C8.13089 1.47206 9.59283 0 11.4101 0C12.3542 0 13.207 0.395938 13.806 1.03553C14.5471 0.893406 15.2578 0.619281 15.8872 0.243656C15.6435 1.00509 15.1258 1.64469 14.4456 2.05075C15.1055 1.97972 15.7451 1.79694 16.3339 1.54316C15.8873 2.19287 15.3289 2.77153 14.6893 3.23856Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>

              <li>
                <Link to="https://www.instagram.com/briddgapp/" target="_blank">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M12.0013 4H12.008M5.0013 1H11.668C13.5089 1 15.0013 2.49238 15.0013 4.33333V11C15.0013 12.8409 13.5089 14.3333 11.668 14.3333H5.0013C3.16035 14.3333 1.66797 12.8409 1.66797 11V4.33333C1.66797 2.49238 3.16035 1 5.0013 1ZM11.0013 7.24667C11.0836 7.8015 10.9888 8.36814 10.7305 8.86601C10.4721 9.36388 10.0634 9.76761 9.56239 10.0198C9.06137 10.272 8.4936 10.3597 7.93983 10.2706C7.38605 10.1815 6.87448 9.92006 6.47786 9.52344C6.08125 9.12683 5.81979 8.61525 5.73068 8.06148C5.64157 7.5077 5.72935 6.93993 5.98152 6.43892C6.23369 5.9379 6.63743 5.52916 7.13529 5.27083C7.63316 5.01249 8.19981 4.91773 8.75464 5C9.32058 5.08392 9.84454 5.34764 10.2491 5.7522C10.6537 6.15677 10.9174 6.68072 11.0013 7.24667Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </li>

              <li>
                <Link to="https://www.tiktok.com/@briddgapp" target="_blank">
                  {/* <svg width="13" height="16" viewBox="0 0 13 16" fill="none">
                  <path
                    d="M6.70898 0C3.50273 0 0.333984 2.1375 0.333984 5.59688C0.333984 7.79688 1.57148 9.04688 2.32148 9.04688C2.63086 9.04688 2.80898 8.18438 2.80898 7.94063C2.80898 7.65 2.06836 7.03125 2.06836 5.82188C2.06836 3.30938 3.98086 1.52813 6.45586 1.52813C8.58398 1.52813 10.159 2.7375 10.159 4.95937C10.159 6.61875 9.49336 9.73125 7.33711 9.73125C6.55898 9.73125 5.89336 9.16875 5.89336 8.3625C5.89336 7.18125 6.71836 6.0375 6.71836 4.81875C6.71836 2.75 3.78398 3.125 3.78398 5.625C3.78398 6.15 3.84961 6.73125 4.08398 7.20937C3.65273 9.06562 2.77148 11.8312 2.77148 13.7437C2.77148 14.3344 2.85586 14.9156 2.91211 15.5063C3.01836 15.625 2.96523 15.6125 3.12773 15.5531C4.70273 13.3969 4.64648 12.975 5.35898 10.1531C5.74336 10.8844 6.73711 11.2781 7.52461 11.2781C10.8434 11.2781 12.334 8.04375 12.334 5.12813C12.334 2.025 9.65273 0 6.70898 0Z"
                    fill="white"
                  />
                </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    id="IconChangeColor"
                    height="16"
                    width="12"
                  >
                    <path
                      d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
                      id="mainIconPathAttribute"
                      fill="white"
                      strokeWidth="1"
                      stroke="#ff0000"
                      filter="url(#shadow)"
                    ></path>
                    <filter id="shadow">
                      <feDropShadow
                        id="shadowValue"
                        stdDeviation=".5"
                        dx="-0.6"
                        dy="0"
                        floodColor="black"
                      ></feDropShadow>
                    </filter>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/briddg/"
                  target="_blank"
                >
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none">
                    <path
                      d="M10.0013 4C11.0622 4 12.0796 4.42143 12.8297 5.17157C13.5799 5.92172 14.0013 6.93913 14.0013 8V12.6667H11.3346V8C11.3346 7.64638 11.1942 7.30724 10.9441 7.05719C10.6941 6.80714 10.3549 6.66667 10.0013 6.66667C9.64768 6.66667 9.30854 6.80714 9.05849 7.05719C8.80845 7.30724 8.66797 7.64638 8.66797 8V12.6667H6.0013V8C6.0013 6.93913 6.42273 5.92172 7.17287 5.17157C7.92302 4.42143 8.94044 4 10.0013 4Z"
                      fill="white"
                    />
                    <path
                      d="M3.33464 4.66667H0.667969V12.6667H3.33464V4.66667Z"
                      fill="white"
                    />
                    <path
                      d="M2.0013 2.66667C2.73768 2.66667 3.33464 2.06971 3.33464 1.33333C3.33464 0.596954 2.73768 0 2.0013 0C1.26492 0 0.667969 0.596954 0.667969 1.33333C0.667969 2.06971 1.26492 2.66667 2.0013 2.66667Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
