// socketSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the socket
const initialState = {
    notification: [],
    onlineUsers:[]
};

// Create a slice with actions and reducers
const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    clearNotification: (state) => {
      return { ...state, notification:[] };
    },
    setNotification: (state, action) => {
      return {
        ...state,
        notification: [action.payload,...state.notification]
      };
    },
    // setNotification: (state, action) => {
    //   return {
    //     ...state,
    //     socket: { ...state.socket, notification: [...state.socket.notification,action.payload] },
    //   };
    // },
    setOnlineUsers:(state,action)=>{
      return{
        ...state,
        onlineUsers:[...action.payload]
      }
    }
  },
});

export const { setSocket,setNotification,clearNotification,setOnlineUsers } = socketSlice.actions;
export default socketSlice.reducer;
