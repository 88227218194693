import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  chatList: [],
};
const fetchChatList = createAsyncThunk("chat/fetchChatList", async () => {
  try {
    const url = `${API_URL.FETCH_ALL_CHATS}?type=GROUP`;
    const result = await Helper.GetData(url);
    return result.data;
  } catch (error) {
    throw error;
  }
});
export const chatListSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChatList.fulfilled, (state, action) => {
      const { status, data } = action.payload;
      let arr = [];
      if (status && data?.length > 0) {
        arr = [...data]
      }
      return {
        ...state,
        chatList:arr,
      };
    });
  },
});
export { fetchChatList };
export default chatListSlice.reducer;
