import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  list: null,
};
const fetchAllPosts = createAsyncThunk("feeds/fetchAllPosts", async (obj) => {
  try {
    const url = `${API_URL.GET_ALL_POSTS}?page=${obj?.page}&user_id=${obj?.user_id}`;
    const result = await Helper.GetData(url);
    return { ...result.data, page:obj?.page };
  } catch (error) {
    throw error;
  }
});
export const feedsSlice = createSlice({
  name: "feeds",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the fulfilled action of fetchAllPosts
    builder.addCase(fetchAllPosts.fulfilled, (state, action) => {
      const { status, data, page } = action.payload;
      let arr = [];
      if (status && data?.length > 0) {
        arr = [...data]
        if (page > 1) {
          arr = [...arr, ...data];
        }
      }
      return {
        ...state,
        list: arr,
      };
    });
  },
});
export { fetchAllPosts };
export default feedsSlice.reducer;
