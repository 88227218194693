import React, { useEffect } from "react";

const LandingHome = ({ setPathname }) => {
  useEffect(() => {
    setPathname("/");
  },[]);
  return (
    <div>
      <iframe
        style={{ border: "0", marginBottom:-7,height: "100vh", width: "100%" }}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src="https://api.briddg.com/index.html"
      ></iframe>
    </div>
  );
};

export default LandingHome;
