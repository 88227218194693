import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Col1Icon from "../../images/college.png";
import PageLoader from "../customcomonents/PageLoader";
import { API_URL } from "../../../utils/API_URL";
import { Helper } from "../../../utils/HelperFunction";
import { Icon } from "@iconify/react";
const CollegeDetails = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const college_id = searchParams.get("key");
  const [data, setData] = useState(null);
  useEffect(() => {
    // let url = `${API_URL.COLLEGE_LIST}?college_id=${college_id}`;
    let url = `${API_URL.COLLEGE_LIST}?college_id=${college_id}&details=true`;
    fetchCollegeList(url);
  }, [college_id]);
  const fetchCollegeList = async (url) => {
    try {
      const result = await Helper.GetData(url);
      const data = result.data?.data;
      let obj;
      const list = data?.map((clg) => {
        obj = {
          id: clg["collegeUnitId"],
          name: clg["name"],
          address: clg["addr"],
          logo: clg["logoImage"],
          admission_rate: clg["acceptanceRate"] || "N/A",
          under_graduate_students: clg["undergraduateSize"] || 0,
          your_price: clg["avgNetPrice"],
          ownership: clg["latest.school.peps_ownership"],
          avg_sat: clg?.satAverage || "N/A",
          avg_act: clg?.actCumulativeMidpoint || "N/A",
          total_students: clg["latest.student.size"] || 0,
          in_state_price: clg["inStateTuition"] || 0,
          out_state_price: clg["outOfStateTuition"],
          roomboard: clg["typicalRoomAndBoard"],
          avg_net_price: clg["avgNetPrice"],
          booksupply: clg["acceptanceRate"],
          aid: clg["latest.aid.number.overall"],
          retention_rate:
            clg["latest.student.retention_rate.overall.full_time"],
          avg_stu_debt_upon_grad: "16,250",
          stu_rec_fin_aid: clg.percentOfStudentsWhoReceiveFinancialAid,
          application_fee: "41",
          full_time_ungrad: "80%",
        };
        // obj = {
        //     id: clg["id"],
        //     name: clg["latest.school.name"],
        //     address: clg["latest.school.address"],
        //     admission_rate:
        //       clg["latest.admissions.admission_rate.overall"] || "N/A",
        //     under_graduate_students:
        //       clg["latest.student.enrollment.undergrad_12_month"] || 0,
        //     your_price: clg["latest.cost.tuition.in_state"],
        //     logo: Col1Icon,
        //     ownership: clg["latest.school.peps_ownership"],
        //     avg_sat: clg["latest.admissions.sat_scores.average.overall"] || "N/A",
        //     avg_act: clg["latest.admissions.act_scores.midpoint.cumulative"] || "N/A",
        //     total_students: clg["latest.student.size"] || 0,
        //     out_state_price: clg["latest.cost.tuition.out_of_state"],
        //     roomboard: clg["latest.cost.roomboard.offcampus"],
        //     avg_net_price: clg["latest.cost.avg_net_price.overall"],
        //     booksupply:clg["latest.cost.booksupply"],
        //     aid: clg["latest.aid.number.overall"],
        //     retention_rate: clg["latest.student.retention_rate.overall.full_time"],
        //     avg_stu_debt_upon_grad: "16,250",
        //     stu_rec_fin_aid: "47%",
        //     application_fee: "41",
        //     full_time_ungrad: "80%",
        // };
        return obj || [];
      });
      setData(list[0]);
    } catch (err) {
      setData([])
      console.error(err);
    }
  };
  return !data ? (
    <PageLoader />
  ) : (
    <div className="collegedtl_sec">
      <Container>
        <Row>
          <Col lg={3}>
            <div className="collegedtl_img">
              <Image src={data.logo} alt="" />
              <h4>{data?.name}</h4>
              <p>
                {data?.address}
                {/* <Icon icon="mdi:dot" /> {data?.ownership} */}
              </p>
            </div>
          </Col>
          <Col lg={9}>
            <div className="collegedtl_content">
              <div className="mb-4">
                <h4 className="collegedtl_head">Admissions</h4>
                <div className="collegedtl_list">
                  <h5>Average SAT</h5>
                  <p>{data.avg_sat}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Average ACT</h5>
                  <p>{data.avg_act}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Acceptance rate</h5>
                  <p>$ {data.booksupply}</p>
                </div>
              </div>
              <div className="mb-4">
                <h4 className="collegedtl_head">Student Life</h4>
                <div className="collegedtl_list">
                  <h5>Undergrads</h5>
                  <p>{data.under_graduate_students}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>No. of Students</h5>
                  <p>{data.total_students}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Full-Time Undergrades</h5>
                  <p>{data.full_time_ungrad}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Retention</h5>
                  <p>{data.retention_rate}</p>
                </div>
              </div>
              <div className="mb-4">
                <h4 className="collegedtl_head">Cost & Financial Aid</h4>
                <h5 className="collegedtl_subhead">Tution</h5>
                <div className="collegedtl_list">
                  <h5>In-State</h5>
                  <p>$ {data.in_state_price}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Out-of-State</h5>
                  <p>$ {data.out_state_price}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Room & Board</h5>
                  <p>$ {data.roomboard}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Average Net Price</h5>
                  <p>$ {data.avg_net_price}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Students Receiving Financial Aid</h5>
                  <p>{data.stu_rec_fin_aid}</p>
                </div>
                <div className="collegedtl_list">
                  <h5>Average Student Debt Upon Graduation</h5>
                  <p>${data.avg_stu_debt_upon_grad}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CollegeDetails;
