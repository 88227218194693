import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  Page: {},
};
const fetchPageList = createAsyncThunk("page/fetchPageList", async (type) => {
  try {
    const url = `${API_URL.GET_PAGE}?type=${type}`;
    const result = await Helper.GetData(url);
    return result.data;
  } catch (error) {
    throw error;
  }
});
export const pageListSlice = createSlice({
  name: "page",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPageList.fulfilled, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        Page: data,
      };
    });
  },
});
export { fetchPageList };
export default pageListSlice.reducer;
