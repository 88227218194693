import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper, LocalStorage } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  _id: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  profile_pic: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  status: "",
  token: "",
  post_count: 0,
  my_network: [],
  blocked_user: [],
  request: [],
};
 const fetchUserData = createAsyncThunk(
  "userDetails/fetchUserData",
  async () => {
    try {
      const url = API_URL.FETCH_PROFILE;
      const result = await Helper.GetData(url);
      return result.data.data;
    } catch (error) {
      console.log(error);
      LocalStorage.clear();
      return {users:{},request: [],post_count: 0}
    }
  }
);
export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    UpdateUser:  (state,action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    LogedOut: (state) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers: (builder) => {
    // Handle the fulfilled action of fetchUserData
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      const { users, post_count, request } = action.payload;

      return {
        
        ...state,
        ...users,
        post_count,
        request,
      };
    });
  },
});

export const { LogedOut,UpdateUser } = userSlice.actions;
export {fetchUserData};
export default userSlice.reducer;
