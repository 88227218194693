import axios from "axios";
import UserImg from "../component/images/user.png";
import Group from "../component/images/group.png";
import axiosInstance from "./ApiInstance";
const isBrowser = typeof window !== "undefined";
export class LocalStorage {
  static get(key) {
    if (!isBrowser) return;
    const value =
      window.localStorage.getItem(key) || window.sessionStorage.getItem(key);
    if (value) {
      try {
        return value;
      } catch (err) {
        return null;
      }
    }
    return null;
  }
  static session(key, value) {
    if (!isBrowser) return;
    window.sessionStorage.setItem(key, value);
  }
  static set(key, value) {
    if (!isBrowser) return;
    localStorage.setItem(key, value);
  }
  static remove(key) {
    if (!isBrowser) return;
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }
  static clear() {
    if (!isBrowser) return;
    localStorage.clear();
    sessionStorage.clear();
  }
}
export const Helper = {
  PostData: async (url, obj) => {
    try {
      return await axiosInstance.post(url, obj);
    } catch (err) {
      console.log("post error", err);
      if (err.response?.data?.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PutData: async (url, obj) => {
    try {
      return await axiosInstance.put(url, obj);
    } catch (err) {
      console.error(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PatchData: async (url, obj) => {
    try {
      return await axiosInstance.patch(url, obj);
    } catch (err) {
      console.log(err);
      if (err.response?.data?.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  GetData: async (url) => {
    try {
      const result = await axiosInstance.get(url);
      return result;
    } catch (err) {
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  FetchData: async (url) => {
    try {
      const result = await axiosInstance.get(url);
      return {
        status: result.data.status,
        message: result.data.message,
        data: result.data.data,
      };
    } catch (err) {
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return { status: false, message: err?.response?.data?.message };
    }
  },
  PostFormData: async (url, obj) => {
    try {
      return await axiosInstance.post(url, obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PatchFormData: async (url, obj) => {
    try {
      return await axiosInstance.patch(url, obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PutFormData: async (url, obj) => {
    try {
      return await axiosInstance.put(url, obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  DeleteData: async (url) => {
    try {
      return await axios.delete(url);
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
};
export const S3_URL = "https://briddggg.s3.amazonaws.com/";
export const validateFields = async (validationSchema, values) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
    return {};
  } catch (validationErrors) {
    const errors = {};
    validationErrors.inner.forEach((error) => {
      if (error.path) {
        errors[error.path] = error.message;
      }
    });
    return errors;
  }
};
export const shortenSentence = (sentence) => {
  const words = sentence?.trim()?.split(/\s+/);
  if (words.length > 4) {
    return words?.slice(0, 4).join(" ") + "...";
  } else {
    return sentence;
  }
};
export const shortenParagraph = (sentence) => {
  const words = sentence.split("");
  if (words.length > 50) {
    return words?.slice(0, 50).join("") + "...";
  } else {
    return sentence;
  }
};
export const localDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
};
export const capitalizeEveryWord = (sentence) => {
  const words = sentence?.split(" ");
  const capitalizedWords = words?.map((word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  });
  const capitalizedSentence = capitalizedWords?.join(" ");
  return capitalizedSentence;
};

export const formatTimeAgo = (createdAt) => {
  const currentDate = new Date();
  const createdDate = new Date(createdAt);
  const timeDifference = currentDate - createdDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds < 10 ? "just now" : seconds + " seconds ago"}`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    return `${days} days ago`;
  } else {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return createdDate.toLocaleDateString("en-US", options);
  }
};
export const formatNumberShort = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "m";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "k";
  } else {
    return number?.toString();
  }
};
export const getChatObjectMetadata = (chat, loggedInUser) => {
  const lastMessage = chat?.last_message?.message
    ? chat?.last_message?.message
    : chat?.last_message
    ? `${chat?.last_message?.media?.length} attachment${
        chat?.last_message?.media?.length > 1 ? "s" : ""
      }`
    : "No messages yet";
  const time =
    chat?.last_message?.createdAt && formatDate(chat?.last_message?.createdAt);
  const participoantsList = chat?.participants
    ?.filter((item) => item._id?.toString() !== loggedInUser?._id?.toString())
    ?.map((item) => item._id);
  if (chat?.is_group_chat) {
    return {
      title: chat?.name,
      pic: chat.group_icon ? S3_URL + chat.group_icon : Group,
      time,
      description: `${chat?.participants?.length} members in the chat`,
      details: chat?.description,
      last_message: chat?.last_message
        ? capitalizeEveryWord(chat?.last_message?.sender?.first_name) +
          ": " +
          lastMessage
        : lastMessage,
      id: "",
      participants: participoantsList,
    };
  } else {
    const participant = chat?.participants?.find(
      (p) => p._id !== loggedInUser?._id
    );
    return {
      title: capitalizeEveryWord(
        participant?.role?.role === "student"
          ? participant?.first_name + " " + participant?.last_name
          : participant?.school_name
      ),
      id: participant?._id,
      pic: participant?.profile_pic
        ? S3_URL + participant?.profile_pic
        : UserImg,
      description: "",
      // description:participant?.email,
      details: participant?.email,
      last_message: shortenSentence(lastMessage),
      time,
    };
  }
};

export const validateEmail = async (email) => {
  try {
    const options = {
      method: "GET",
      url: "https://api.dev.me/v1-get-email-details",
      params: { email: email },
      headers: {
        Accept: "application/json",
        "x-api-key": "65c1dbef646c34bf9b8820a4-e2b537f3d768",
      },
    };
    const result = await axios.request(options);
    if (result) {
      if (result.data.validFormat === false) {
        return { email: "Enter valid email" };
      }
      if (result.data.isDisposable) {
        return { email: "Disposable email not allowed" };
      }
      return null;
    }
  } catch (err) {
    if (err.response.data.status === 400) {
      if (err.response.data.message === "invalid value") {
        return { email: "Enter valid email" };
      }
    } else {
      console.error("Error", err);
    }
  }
};

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a target="_blank" className="link-primary" href={part}>
          {part}
        </a>
      );
    }
    return part;
  });
}

export const getCommentsLength = (comments) => {
  let length = comments?.length;
  comments?.forEach((comment) => {
    length += getCommentsLength(comment?.replies);
  });

  return length;
};
export const formatDate = (dateString) => {
  const createdAt = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const formatDateOnly = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  if (createdAt.toDateString() === today.toDateString()) {
    return formatTime(createdAt);
  } else if (createdAt.toDateString() === yesterday.toDateString()) {
    return `Yesterday`;
  } else {
    const month = createdAt.toLocaleString("default", { month: "short" });
    const currentMonth = today.toLocaleString("default", { month: "short" });
    const year = createdAt.getFullYear();
    const currentYear = today.getFullYear();
    if (
      year === currentYear &&
      month === currentMonth &&
      createdAt.getDate() > today.getDate() - 9
    ) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayOfWeek = days[createdAt.getDay()];
      return dayOfWeek;
    } else {
      return formatDateOnly(createdAt);
    }
  }
};
export const formatGroupMessageDate = (dateString) => {
  const createdAt = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const formatDateOnly = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  if (createdAt.toDateString() === today.toDateString()) {
    return "Today";
  } else if (createdAt.toDateString() === yesterday.toDateString()) {
    return `Yesterday`;
  } else {
    const month = createdAt.toLocaleString("default", { month: "short" });
    const currentMonth = today.toLocaleString("default", { month: "short" });
    const year = createdAt.getFullYear();
    const currentYear = today.getFullYear();
    if (
      year === currentYear &&
      month === currentMonth &&
      createdAt.getDate() > today.getDate() - 9
    ) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayOfWeek = days[createdAt.getDay()];
      return dayOfWeek;
    } else {
      return formatDateOnly(createdAt);
    }
  }
};
const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
export const groupMessagesByDate = (messages) => {
  const groups = {};
  messages.forEach((msg) => {
    const formattedDate = formatGroupMessageDate(msg.createdAt);
    if (!groups[formattedDate]) {
      groups[formattedDate] = [];
    }
    groups[formattedDate].push(msg);
  });
  return groups;
};
export const getTimeFromDate = (dateString) => {
  const createdAt = new Date(dateString);
  return formatTime(createdAt);
};

export const extractWithoutFirstMention = (text) => {
  const index = text.indexOf("@[");
  if (index !== -1) {
    return text.replace(/@\[[^\]]+\]\([^)]+\)/, "").trim();
  } else {
    return text;
  }
};

export const addSpanToWordsStartingWithHash = (input, dot) => {
  const dots = dot ? "..." : "";
  const words = input.match(/\S+|\s+|\r\n/g);

  let result = "";

  for (let i = 0; i < words.length; i++) {
    if (words[i].trim().startsWith("#")) {
      result += `<span style="color:blue">${words[i]}</span>`;
    } else {
      result += words[i];
    }
  }
  return `${result.trim()}${dots}`;
};
