import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import socketSlice from "./socketSlice";
import notificationSlice from "./NotificationSlice";
import feedsSlice from "./feedsSlice";
import chatListSlice from "./chatListSlice";
import unreadMessageSlice from "./messageNotificationSlice";
import pageListSlice from "./pageSlice";
import majorSlice from "./majorSlice";
import networkSlice from "./networkSlice";

export const store = configureStore({
  reducer: {
    User: userSlice,
    Socket: socketSlice,
    Notification: notificationSlice,
    Post: feedsSlice,
    Chat: chatListSlice,
    UnreadMsg: unreadMessageSlice,
    Page: pageListSlice,
    Major: majorSlice,
    Connection: networkSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
