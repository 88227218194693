import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Helper } from "../utils/HelperFunction";
import { API_URL } from "../utils/API_URL";

const initialState = {
  unreadMessage: [],
};
const fetchUnreadMessage = createAsyncThunk("message/fetchUnreadMessage", async () => {
  try {
    const url = API_URL.FETCH_UNREAD_MESSAGES;
    const result = await Helper.GetData(url);
    return result.data;
  } catch (error) {
    throw error;
  }
});
const updateUnreadMessage = createAsyncThunk("message/updateUnreadMessage", async (obj,{dispatch}) => {
  try {
    const url = API_URL.UPDATE_UNREAD_MESSAGES;
    const result = await Helper.PatchData(url,obj);
    dispatch(fetchUnreadMessage())
    return result.data;
  } catch (error) {
    throw error;
  }
});
export const unreadMessageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUnreadMessage.fulfilled, (state, action) => {
      const { status, data } = action.payload;
      let arr = [];
      if (status && data?.length > 0) {
        arr = [...data]
      }
      return {
        ...state,
        unreadMessage:arr,
      };
    });
    builder.addCase(updateUnreadMessage.fulfilled, (state, action) => {});
  },
});
export { fetchUnreadMessage,updateUnreadMessage };
export default unreadMessageSlice.reducer;
